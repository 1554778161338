import {Component, OnInit} from '@angular/core';
import {FilteredLinkService} from '../shared-module/services/filtered-link-banner.service';
import {ActivatedRoute} from '@angular/router';
import {switchMap, tap} from 'rxjs/operators';
import {IArtwork} from '../shared-module/interfaces';

@Component({
    selector: 'app-filtered-link',
    templateUrl: './filtered-link.component.html',
    styleUrls: ['./filtered-link.component.scss']
})
export class FilteredLinkComponent implements OnInit {
    artworks: IArtwork[] = [];
    params: any = {};
    title: string = '';
    count = 0;

    constructor(private filteredLinkService: FilteredLinkService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        
        this.route.queryParams.subscribe((params) => {
            switch (params.filterParam) {
                case 'under500':
                    this.title = "Under $500"
                    break;
                case 'under1000':
                    this.title = "Under $1000"
                    break;
                case 'under1500':
                    this.title = "Under $1500"
                    break;
                case 'largeWorks':
                    this.title = "Large Works"
                    break;
                case 'womensArt':
                    this.title = "Women's Art"
                    break;
                case 'oversizedArt':
                    this.title = "Oversized Art"
                    break;
            }
        });

        this.route.queryParams.pipe(
            tap(params => this.params = params),
            switchMap(params => this.filteredLinkService.filterArtworks(params))
        ).subscribe(({results, count}) => {
            this.artworks = results;
            this.count = count;
        });
    }
}
