import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {IApiReceiveResponse, IApiResponse, IOrder} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
    constructor(private apiService: ApiService) {
    }

    getOrders(params: any = {}): Observable<IApiReceiveResponse<IOrder>> {
        return this.apiService.get<IApiReceiveResponse<IOrder>>('orders/receive/all', {params}, true);
    }

    getOrder(orderId: string, params: any = {}): Observable<IOrder> {
        return this.apiService.get<IOrder>(`orders/receive/${orderId}`, {params}, true);
    }

    addOrder(order: IOrder): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>('orders/add', order, {}, true);
    }

    confirmOrder(orderId: string, data: any = {}, params: any = {}): Observable<IApiResponse> {
        return this.apiService.post<IApiResponse>(`orders/confirm/${orderId}`, data, {params}, true);
    }

    createPaypalOrder(orderId: string): Observable<{ url }> {
        return this.apiService.post<{ url }>(`orders/paypal/add/${orderId}`, {}, {}, true);
    }
}
